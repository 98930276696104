import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../App.css';

import logo from '../Images/Logo.jpg';
import sceneBackground from '../Images/scene-background.jpg';
import box from '../Images/Box.png';
import gameImage1 from '../Images/game-image-1.jpg';
import gameImage2 from '../Images/game-image-2.jpg';
import gameImage3 from '../Images/game-image-3.jpg';
import gameImage4 from '../Images/game-image-4.jpg';
import gameImage5 from '../Images/game-image-5.jpg';
import gameImageRadio1 from '../Images/game-image-radio-1.jpg';
import gameImageRadio2 from '../Images/game-image-radio-2.jpg';
import gameImageRadio3 from '../Images/game-image-radio-3.jpg';
import arrowRight from '../Images/arrow-right-solid.svg';
import confetti from '../Images/confetti.jpg';
import beerCan from '../Images/beercan.png';
import cardStackYellow from '../Images/card-stack-yellow.png';
import cardStackPurple from '../Images/card-stack-purple.png';
import cardStackGreen from '../Images/card-stack-green.png';
import loading from '../Images/loading.gif';


class HomePage extends Component {
  
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.getElementById("radio-message").innerHTML = "Beer Cards have a drinking task and a point value. Complete harder drinking tasks to score more points for your team.";

        // scrolling animation on image1 element
        var target = document.getElementById("image1");
        window.addEventListener("scroll", function() {
            if (window.scrollY > (target.offsetTop + (target.offsetHeight*1))) {
                target.style.animation = "bounceIn 0.5s linear forwards";
            }
        });
    }

    scrollSmooth = e => {
        window.scrollTo({top: "0", behavior: "smooth"});
    }

    radioYellowClicked = e => {
        document.getElementById("radio-yellow").className = "radio-selected";
        document.getElementById("radio-purple").className = "radio";
        document.getElementById("radio-green").className = "radio";

        document.getElementById("radio-message").innerHTML = "Beer Cards have a drinking task and a point value. Complete harder drinking tasks to score more points for your team.";
        document.getElementById("radio-image").src = gameImageRadio1;
    }
    radioPurpleClicked = e => {
        document.getElementById("radio-purple").className = "radio-selected";
        document.getElementById("radio-yellow").className = "radio";
        document.getElementById("radio-green").className = "radio";

        document.getElementById("radio-message").innerHTML = "Additive Cards are played on top of beer cards to influence drinking tasks. Use them offensively on your opponents or defensively on you and your team.";
        document.getElementById("radio-image").src = gameImageRadio2;
    }
    radioGreenClicked = e => {
        document.getElementById("radio-green").className = "radio-selected";
        document.getElementById("radio-yellow").className = "radio";
        document.getElementById("radio-purple").className = "radio";

        document.getElementById("radio-message").innerHTML = "Game Cards are mini games played after every player has taken a turn. Trade hands, make up a rule, and more!";
        document.getElementById("radio-image").src = gameImageRadio3;
    }
    launchKickstarter = e => {
        window.location.href="https://www.kickstarter.com/projects/7300games/additive-drinking-the-card-game-for-drinkers";
    }
  
    render () {

    const styleConfetti = {
        backgroundImage: "url(" + confetti + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    }
    const styleVideoLoader = {
        backgroundImage: "url(" + loading + ")",
        backgroundSize: "40px 40px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    }
  
      return (
          
        <div className="HomePage">

            <div className="header">
                <div className="header-main">

                    <div className="sized-container">

                        <Link className="link-class" onClick={this.scrollSmooth}>
                            <div className="logo-container">
                                <img className="logo" src={logo}/>
                            </div>
                        </Link>

                        <div className="nav">
                            <Link className="link-class" >
                                <p className="nav-item-pressed" onClick={this.scrollSmooth}>THE GAME</p>
                            </Link>
                            <Link className="link-class" to="/howtoplay">
                                <p className="nav-item" >HOW TO PLAY</p>
                            </Link>
                            <Link className="link-class" to="/purchase">
                                <p className="nav-item" >PURCHASE</p>
                            </Link>
                        </div>
                        <div className="nav-underline">
                            <div className="underline"></div>
                            <div className="underline" style={{opacity: "0"}}></div>
                            <div className="underline" style={{opacity: "0"}}></div>
                        </div>

                </div>



                </div>
                <div className="header-expanded" style={{display: "none"}}>
                    <div className="sized-container">
                        <div className="nav-instructions">
                            <Link className="link-class" to="/howtoplay">
                                <div id="id-inst-howtoplay" className="nav-instructions-item-pressed" onClick={this.handleInstructionsHowToPlayClick}>
                                    THE<br></br>RULES
                                </div>
                            </Link>

                            <Link className="link-class" to="/beercards">
                                <div id="id-inst-beercards" className="nav-instructions-item" onClick={this.handleInstructionsBeerCardsClick}>
                                    BEER<br></br>CARDS
                                </div>
                            </Link>

                            <Link className="link-class" to="/additivecards">
                                <div id="id-inst-additivecards" className="nav-instructions-item" onClick={this.handleInstructionsAdditiveCardsClick}>
                                    ADDITIVE<br></br>CARDS
                                </div>
                            </Link>
                            
                            <Link className="link-class" to="/gamecards">
                                <div id="id-inst-gamecards" className="nav-instructions-item" onClick={this.handleInstructionsGameCardsClick}>
                                    GAME<br></br>CARDS
                                </div>
                            </Link>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="Content">

                <div className="scene">
                    <img className="scene-background" src={sceneBackground}></img>

                    <div className="scene-ui-container">

                        <div className='video-container'>
                            <div className="video">
                                <iframe 
                                    frameBorder="0"
                                    backgroundColor="#2E213B"
                                    src="https://www.youtube.com/embed/xfQL29gM0MQ"
                                    style={styleVideoLoader}>
                                </iframe> 
                            </div>

                        </div>

                        

                        <Link to="/purchase">
                        <div className="test-flex">
                            <div className="button-purchase">

                            </div>

                            <div className="box-text-container">
                                <img className="box" src={box}></img>
                                <p className="button-box-text">PURCHASE</p>
                            </div>

                        </div>
                        </Link>

                    </div>

                </div>
                            

                <div className="game-section-purple">

                    <div className="sized-container">

                        <div>
                            <p className="game-subtitle">START YOUR PREGAME</p>
                        
                            <p className="game-text">Complete drinking tasks. Score points. Influence your opponents’ drinking tasks with an endless combination of additive cards.</p>

                            <div className="game-image-sizeA-wrapper">
                                <img id="image1" className="game-image" src={gameImage1}></img>
                            </div>

                        </div>

                        <img className="beer-can-tr1" src={beerCan}></img>
                        <img className="beer-can-tr2" src={beerCan}></img>
                        <img className="beer-can-tl1" src={beerCan}></img>
                        <img className="beer-can-bl1" src={beerCan}></img>
                    
                    </div>


                </div>

                <div className="game-section-light" style={styleConfetti}>

                    <div className="sized-container">


                        <p className="game-subtitle">KICKSTARTER FUNDED</p>
                    
                        <p className="game-text">Additive Drinking was first created in a dank college apartment in the town of Blacksburg, Virginia. After 3 years of perfecting the game, it was released on Kickstarter in 2020.</p>

                        <div className="kickstarter-link-container" onClick={this.launchKickstarter}>
                            <span>GO TO THE KICKSTARTER</span>
                            <img className="arrow-icon" src={arrowRight}></img>
                        </div>


                    </div>

                </div>

                <div className="game-section-purple">

                    <div className="sized-container">

                        <p className="game-subtitle">THE CARDS</p>

                        <div className="radio-container">
                            <div id="radio-yellow" className="radio-selected" onClick={this.radioYellowClicked}>
                                <p className="radio-text">BEER<br></br>CARDS</p>
                                <img className="card-stack" src={cardStackYellow}></img>
                            </div>
                            <div id="radio-purple" className="radio" onClick={this.radioPurpleClicked}>
                                <p className="radio-text">ADDITIVE<br></br>CARDS</p>
                                <img className="card-stack" src={cardStackPurple}></img>
                            </div>
                            <div id="radio-green" className="radio" onClick={this.radioGreenClicked}>
                                <p className="radio-text">GAME<br></br>CARDS</p>
                                <img className="card-stack" src={cardStackGreen}></img>
                            </div>
                        </div>
                    
                        <div className="radio-message-container">
                            <p id="radio-message" className="game-text"></p>
                        </div>

                        <div className="game-image-container">
                            <img id="radio-image" className="game-image-radio" src={gameImageRadio1}></img>
                            <img className="secret-image" src={gameImageRadio2}></img>
                            <img className="secret-image" src={gameImageRadio3}></img>

                        </div>

                        <img className="beer-can-tl1" src={beerCan}></img>
                        <img className="beer-can-tr1" src={beerCan}></img>
                        <img className="beer-can-bl1" src={beerCan}></img>
                        <img className="beer-can-br1" src={beerCan}></img>

                    </div>

                </div>

                <div className="game-section-light">

                    <div style={{marginBottom: "60px"}} className="sized-container">

                        <p className="game-subtitle">ENDLESS CARD COMBINATIONS</p>
                    
                        <p className="game-text">Stack additive cards on top of drinking tasks for unpredicable gameplay. Players have the power to influence drinking tasks to gain the advantage using a little bit of strategy and luck of the draw.</p>

                        <div className="game-image-sizeB-wrapper">
                            <img className="game-image" src={gameImage2}></img>
                        </div>
                        <p className="game-text-special">= 4 drinks</p>

                        <div className="game-image-sizeB-wrapper">
                            <img className="game-image" src={gameImage3}></img>
                        </div>
                        <p className="game-text-special">= 6 drinks</p>

                        <div className="game-image-sizeB-wrapper">
                            <img className="game-image" src={gameImage4}></img>
                        </div>
                        <p className="game-text-special">= 3 seconds</p>

                        <div className="game-image-sizeB-wrapper">
                            <img className="game-image" src={gameImage5}></img>
                        </div>
                        <p className="game-text-special">= 6 drinks</p>
                        

                    </div>

                    <Link to="/purchase">
                        <div className="test-flex">
                            <div className="button-purchase">

                            </div>

                            <div className="box-text-container">
                                <img className="box" src={box}></img>
                                <p className="button-box-text">PURCHASE</p>
                            </div>

                        </div>
                    </Link>

                </div>
                


                

                
            </div>



            
            

            <div className="HomePageFooter">
                <p className="footerLine1">© <b>Additive Drinking</b></p>
                <p className="footerLine2">Created by Brandon Falcone</p>
                <p className="footerLine3">additivedrinking@gmail.com</p>
            </div>

        </div>
      );
    }
  }
  
  export default withRouter(HomePage);

