import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import logo from '../Images/Logo.jpg';
import instructionsImage3 from '../Images/instructions-image-3.jpg';
import instructionsImage4 from '../Images/instructions-image-4.jpg';
import instructionsImage5 from '../Images/instructions-image-5.jpg';


class AdditiveCards extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    scrollSmooth = e => {
        window.scrollTo({top: "0", behavior: "smooth"});
    }

    render () {
  
      return (
          
        <div className="AdditiveCards">

          <div className="header">
              <div className="header-main">

                  <div className="sized-container">

                      <Link className="link-class" to="/">
                          <div className="logo-container">
                              <img className="logo" src={logo}/>
                          </div>
                      </Link>

                      <div className="nav">
                          <Link className="link-class" to="/">
                              <p className="nav-item" >THE GAME</p>
                          </Link>
                          <Link className="link-class" to="/howtoplay">
                              <p className="nav-item-pressed" >HOW TO PLAY</p>
                          </Link>
                          <Link className="link-class" to="/purchase">
                              <p className="nav-item" >PURCHASE</p>
                          </Link>
                      </div>
                      <div className="nav-underline">
                          <div className="underline" style={{opacity: "0"}}></div>
                          <div className="underline" ></div>
                          <div className="underline" style={{opacity: "0"}}></div>
                      </div>

              </div>



              </div>
              <div className="header-expanded">
                  <div className="sized-container">
                      <div className="nav-instructions">
                          <Link className="link-class" to="/howtoplay">
                              <div id="id-inst-howtoplay" className="nav-instructions-item" onClick={this.handleInstructionsHowToPlayClick}>
                                  THE<br></br>RULES
                              </div>
                          </Link>

                          <Link className="link-class" to="/beercards">
                              <div id="id-inst-beercards" className="nav-instructions-item" onClick={this.handleInstructionsBeerCardsClick}>
                                  BEER<br></br>CARDS
                              </div>
                          </Link>

                          <Link className="link-class" onClick={this.scrollSmooth}>
                              <div id="id-inst-additivecards" className="nav-instructions-item-pressed" onClick={this.handleInstructionsAdditiveCardsClick}>
                                  ADDITIVE<br></br>CARDS
                              </div>
                          </Link>
                          
                          <Link className="link-class" to="/gamecards">
                              <div id="id-inst-gamecards" className="nav-instructions-item" onClick={this.handleInstructionsGameCardsClick}>
                                  GAME<br></br>CARDS
                              </div>
                          </Link>
                          
                          
                      </div>
                  </div>
              </div>
          </div>

          <div className="ContentInstructions">

            <div className="sized-container">

              <div className="instructions-group">
                <p className="game-subtitle">Additive Cards</p>
                <p className="game-text">Play an additive card from one of the 3 cards in your hand by throwing it down on top of any beer card that's in play.</p>
                <div className="game-image-sizeD-wrapper">
                    <img className="game-image" src={instructionsImage3}></img>
                </div>
              </div>

              <div className="instructions-group">
                <p className="game-subtitle">Types of Additive Cards</p>
                <p className="game-text">There are defensive and offensive cards. Use them to help you and your teammates and to target your opponents.</p>
                <div className="game-image-sizeF-wrapper">
                    <img className="game-image" src={instructionsImage4}></img>
                </div>
              </div>

              <div className="instructions-group">
                <p className="game-subtitle">Stack Additive Cards</p>
                <p className="game-text">Use any number of additive cards to get the job done. Challenge your opponents’ drinking abilities and have fun creating crazy combinations in Additive Drinking.</p>
                <div className="game-image-sizeB-wrapper">
                    <img className="game-image" src={instructionsImage5}></img>
                </div>
              </div>



            </div>
            
          </div>
          
          <div className="HomePageFooter">
              <p className="footerLine1">© <b>Additive Drinking</b></p>
              <p className="footerLine2">Created by Brandon Falcone</p>
              <p className="footerLine3">additivedrinking@gmail.com</p>
          </div>
           
        </div>
      );
    }
  }
  
  export default withRouter(AdditiveCards);