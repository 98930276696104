import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import logo from '../Images/Logo.jpg';

class ContentInstructions extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var target = document.getElementById("theRules");
    console.log(target.offsetTop);
    console.log(target.offsetHeight);
  }

  scrollSmooth = e => {
    window.scrollTo({top: "0", behavior: "smooth"});
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  scrollObjective = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-objective");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollSetup = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-setup");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollFirstTurn = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-first-turn");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollBeerCards = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-beer-cards");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollAdditiveCards = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-additive-cards");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollScoring = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-scoring");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollWinning = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-winning");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  scrollGameCards = e => {
    var start = document.getElementById("theRules");
    var target = document.getElementById("link-game-cards");
    var offsetTop = parseInt(target.offsetTop) - parseInt(start.offsetTop);
    window.scrollTo({top: offsetTop, behavior: "smooth"});
    target.style.animation = "colorFade 1s ease-in";
    this.delay(1500).then(() => {
      target.style.animation = null;
    });
  }
  
    componentWillUnmount(){

    }

    render () {
  
      return (
          
        <div className="Instructions">

          <div className="header">
              <div className="header-main">

                  <div className="sized-container">

                      <Link className="link-class" to="/">
                          <div className="logo-container">
                              <img className="logo" src={logo}/>
                          </div>
                      </Link>

                      <div className="nav">
                          <Link className="link-class" to="/">
                              <p className="nav-item" >THE GAME</p>
                          </Link>
                          <Link className="link-class" onClick={this.scrollSmooth}>
                              <p className="nav-item-pressed" >HOW TO PLAY</p>
                          </Link>
                          <Link className="link-class" to="/purchase">
                              <p className="nav-item" >PURCHASE</p>
                          </Link>
                      </div>
                      <div className="nav-underline">
                          <div className="underline" style={{opacity: "0"}}></div>
                          <div className="underline"></div>
                          <div className="underline" style={{opacity: "0"}}></div>
                      </div>

              </div>



              </div>
              <div className="header-expanded">
                  <div className="sized-container">
                      <div className="nav-instructions">
                          <Link className="link-class" onClick={this.scrollSmooth}>
                              <div id="id-inst-howtoplay" className="nav-instructions-item-pressed" onClick={this.handleInstructionsHowToPlayClick}>
                                  THE<br></br>RULES
                              </div>
                          </Link>

                          <Link className="link-class" to="/beercards">
                              <div id="id-inst-beercards" className="nav-instructions-item" onClick={this.handleInstructionsBeerCardsClick}>
                                  BEER<br></br>CARDS
                              </div>
                          </Link>

                          <Link className="link-class" to="/additivecards">
                              <div id="id-inst-additivecards" className="nav-instructions-item" onClick={this.handleInstructionsAdditiveCardsClick}>
                                  ADDITIVE<br></br>CARDS
                              </div>
                          </Link>
                          
                          <Link className="link-class" to="/gamecards">
                              <div id="id-inst-gamecards" className="nav-instructions-item" onClick={this.handleInstructionsGameCardsClick}>
                                  GAME<br></br>CARDS
                              </div>
                          </Link>
                          
                          
                      </div>
                  </div>
              </div>
          </div>

          <div className="ContentInstructions">

            <div className="sized-container">

              <div className="instructions-group">
                <p id="theRules" className="instructions-subtitle">The Rules</p>

                <div className="toc-links">
                  <span className="toc-item" onClick={this.scrollObjective}>Objective</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollSetup}>Setup</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollFirstTurn}>The First Turn</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollBeerCards}>Using Beer Cards</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollAdditiveCards}>Using Additive Cards</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollScoring}>Scoring Points and Opting Out</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollWinning}>Winning the Game</span>
                  <span className="toc-divider">/</span>
                  <span className="toc-item" onClick={this.scrollGameCards}>Using Game Cards</span>
                </div>
              </div>

              <div className="instructions-group">
                <p id="link-objective" className="instructions-subtitle">Objective</p>
                <p className="instructions-text">The objective is to complete as many drinking tasks as you can, scoring points for your team in the process.</p>
                <p className="instructions-text">By completing a drinking task on a beer card, you can earn 1, 2, or 3 points depending on the difficulty of the task.</p>
                <p className="instructions-text">Failing to complete a drinking task or opting out will earn no points.</p>
                <p className="instructions-text">Use additive cards on your opponent's drinking tasks to increase the amount they have to drink.</p>
                <p className="instructions-text">Or use additive cards to reduce the amount you and your teammates have to drink.</p>
                <p className="instructions-text">Be the first team to reach a set number of points to win.</p>
              </div>

              <div className="instructions-group">
                <p id="link-setup" className="instructions-subtitle">Setup</p>
                <p className="instructions-text">Divide into 2 teams.</p>
                <p className="instructions-text">Shuffle the beer cards (yellow) and additive cards (purple) together, and deal out 3 cards from the deck face-down to each player.</p>
                <p className="instructions-text">Place the remainder of the deck in the center of the table, face-down.</p>
                <p className="instructions-text">Keep the deck of game cards (green) to the side for later.</p>
                <p className="instructions-text">Designate a player to keep track of the teams' scores.</p>
              </div>
              
              <div className="instructions-group">
                <p id="link-first-turn" className="instructions-subtitle">The First Turn</p>
                <p className="instructions-text">Decide who will go first.</p>
                <p className="instructions-text">Begin your turn by playing one beer card from your hand and playing it face up on the discard pile. If you don't have a beer card in your hand, discard a card and replace it with a card in the deck until you get a beer card.</p>
                <p className="instructions-text">Once played, any player has the option to influence the drinking task by playing an additive card from their hand and throwing it down on top of the beer card.</p>
                <p className="instructions-text">When everyone is done playing additive cards, the player will assess the final count of drinks and decide to attempt the drinking task or opt out.</p>
                <p className="instructions-text">Points are awarded upon successful completion of a drinking task as indicated at the top of the beer card.</p>
                <p className="instructions-text">If a player chooses to opt out of a drinking task, any teammate has the option to complete the drinking task on behalf of the player in order to earn the points.</p>
                <p className="instructions-text">End your turn by drawing from the deck. Any other players should draw from the deck as well to maintain 3 cards in their hand.</p>
                <p className="instructions-text">A player on the opposite team begins their turn next.</p>
                <p className="instructions-text">When there are no more cards left to draw, reshuffle the discard pile and continue playing with these cards.</p>

              </div>
              
              <div className="instructions-group">
                <p id="link-beer-cards" className="instructions-subtitle">Using Beer Cards</p>
                <p className="instructions-text">Beer cards have a drinking task and a point value of 1, 2 or 3 points.</p>
                <p className="instructions-text">A drinking task that requires more drinks will have a higher point value.</p>
                <p className="instructions-text">When a beer card is in play, all players should have ample time to play additive cards before the drinking task is attempted.</p>
                <p className="instructions-text">When no more additive cards have been played, you can choose to attempt the drinking task or opt out. </p>
              </div>
              
              <div className="instructions-group">
                <p id="link-additive-cards" className="instructions-subtitle">Using Additive Cards</p>
                <p className="instructions-text">Additve cards can be used to increase or decrease the amount of drinking on a drinking task.</p>
                <p className="instructions-text">Play an additive card by throwing it down on top of a beer card that’s in play. There’s no limit to how many can be played on a turn.</p>
                <p className="instructions-text">You can use additive cards on yourself, your teammates, and your opponents.</p>
                <p className="instructions-text">Arithmatic for computing the amount of drinks is performed in the order the additive cards were played. If arithmatic results in a fraction of drinks then drinks are rounded down to the nearest whole number. </p>
                <p className="instructions-text">A reasonable amount of time should take place after a beer card has been played to allow players to play additive cards. When the dust has settled and no more additive cards are being played, then the drinking task can be attempted.</p>
              </div>

              <div className="instructions-group">
                <p id="link-scoring" className="instructions-subtitle">Scoring Points and Opting Out</p>
                <p className="instructions-text">Complete drinking tasks on beer cards to score points for your team.</p>
                <p className="instructions-text">If you are not capable of completing the drinking task, then you can opt out.</p>
                <p className="instructions-text">Opting out will earn no points. However, a teammate can volunteer to attempt the drinking task on the player's behalf. If a teammate is successful in completing the drinking task, then the points can be earned.</p>
              </div>

              <div className="instructions-group">
                <p id="link-winning" className="instructions-subtitle">Winning the Game</p>
                <p className="instructions-text">Be the first team to reach a set number of points to win. Decide how many points to play to based on your drinking abilities and time constraints.</p>
                <p className="instructions-text">Easy Mode: 10 points to win</p>
                <p className="instructions-text">Medium Mode: 20 points to win</p>
                <p className="instructions-text">Hard Mode: 30 points to win</p>
              </div>
              
              <div className="instructions-group">
                <p id="link-game-cards" className="instructions-subtitle">Using Game Cards</p>
                <p className="instructions-text">Game cards have bonus drinking events.</p>
                <p className="instructions-text">Play a game card when a round is completed (that's when every player has taken a turn).</p>
                <p className="instructions-text">Have a player draw from the game card deck and read aloud the card to activate the bonus drinking event.</p>
              </div>
              
              

            </div>
            
          </div>
          
          <div className="HomePageFooter">
              <p className="footerLine1">© <b>Additive Drinking</b></p>
              <p className="footerLine2">Created by Brandon Falcone</p>
              <p className="footerLine3">additivedrinking@gmail.com</p>
          </div>
           
        </div>
      );
    }
  }
  
  export default withRouter(ContentInstructions);