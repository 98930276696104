import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route, Link} from 'react-router-dom';
import './App.css';

import Game from './Components/Game';
import Instructions from './Components/Instructions';
import BeerCards from './Components/BeerCards';
import AdditiveCards from './Components/AdditiveCards';
import GameCards from './Components/GameCards';
import Purchase from './Components/Purchase';



class App extends Component {


  componentDidMount() {
    
  }

  


  render () {
    return (
        <BrowserRouter>
          <div id="app" className="App">

            <div id="dpi" style={{height: "1in", width: "1in", left: "100%", position: "fixed", top: "100%"}}></div>
            <Route path="/" exact component={Game} />
            <Route path="/howtoplay" exact component={Instructions} />
            <Route path="/beercards" exact component={BeerCards} />
            <Route path="/additivecards" exact component={AdditiveCards} />
            <Route path="/gamecards" exact component={GameCards} />
            <Route path="/purchase" exact component={Purchase} />

          </div>
        </BrowserRouter>
        
    );
  }
}

export default App;