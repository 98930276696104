import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactDOM from "react-dom";
import PaypalButton from './PaypalButton';

import logo from '../Images/Logo.jpg';
import box from '../Images/Box.png';
import loading from '../Images/loading.gif';


class ContentPurchase extends Component {

    constructor(props) {
      super(props);
    }

    scrollSmooth = e => {
      window.scrollTo({top: "0", behavior: "smooth"});
    }


    state = {
        showPayPal: false
    }

    handlePurchaseClick = (e) => {
      document.getElementById("checkoutButton").style.minHeight = "120px";

      this.setState({showPayPal: true});

      // hide the loader animation so it does not show up after a purchase
      this.delay(10000).then(() => {
        if (document.getElementById("paypalLoader") != null) {
          document.getElementById("paypalLoader").style.display = "none";
        }
      });

    }
  
  
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }
  
  
    render () {
      const { showPayPal } = this.state;

      const styleVideoLoader = {
        backgroundImage: "url(" + loading + ")",
        backgroundSize: "40px 40px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }

      return (

      <div>

        <div className="Purchase">

          <div className="header">
              <div className="header-main">

                  <div className="sized-container">

                      <Link className="link-class" to="/">
                          <div className="logo-container">
                              <img className="logo" src={logo}/>
                          </div>
                      </Link>

                      <div className="nav">
                          <Link className="link-class" to="/">
                              <p className="nav-item" >THE GAME</p>
                          </Link>
                          <Link className="link-class" to="/howtoplay">
                              <p className="nav-item" >HOW TO PLAY</p>
                          </Link>
                          <Link className="link-class" onClick={this.scrollSmooth}>
                              <p className="nav-item-pressed" >PURCHASE</p>
                          </Link>
                      </div>
                      <div className="nav-underline">
                          <div className="underline" style={{opacity: "0"}}></div>
                          <div className="underline" style={{opacity: "0"}}></div>
                          <div className="underline"></div>
                      </div>

              </div>



              </div>
              <div className="header-expanded" style={{display: "none"}}>
                  <div className="sized-container">
                      <div className="nav-instructions">
                          <Link className="link-class" to="/howtoplay">
                              <div id="id-inst-howtoplay" className="nav-instructions-item-pressed" onClick={this.handleInstructionsHowToPlayClick}>
                                  THE<br></br>RULES
                              </div>
                          </Link>

                          <Link className="link-class" to="/beercards">
                              <div id="id-inst-beercards" className="nav-instructions-item" onClick={this.handleInstructionsBeerCardsClick}>
                                  BEER<br></br>CARDS
                              </div>
                          </Link>

                          <Link className="link-class" to="/additivecards">
                              <div id="id-inst-additivecards" className="nav-instructions-item" onClick={this.handleInstructionsAdditiveCardsClick}>
                                  ADDITIVE<br></br>CARDS
                              </div>
                          </Link>
                          
                          <Link className="link-class" to="/gamecards">
                              <div id="id-inst-gamecards" className="nav-instructions-item" onClick={this.handleInstructionsGameCardsClick}>
                                  GAME<br></br>CARDS
                              </div>
                          </Link>
                          
                          
                      </div>
                  </div>
              </div>
          </div>

          <div className="ContentInstructions">

            <div className="sized-container">

              <div className="cart-container">
                <div className="image-container">
                    <img src={box}></img>
                </div>
                <div>
                    <p className="purchase-subtitle">Additive Drinking</p>
                    <p className="purchase-text">The card game & drinking game containing all 61 cards from the official Kickstarter.</p>
                </div>
              </div>

              <table className="purchase-table">
                <tr>
                    <th>Quantity</th>
                    <td>x1</td>
                </tr>
                <tr>
                    <th>Price</th>
                    <td>$19.99</td>
                </tr>
                <tr>
                    <th>Shipping</th>
                    <td>Free</td>
                </tr>     
              </table>

              <div id="checkoutButton" className="checkout-button" onClick={this.handlePurchaseClick}>
                Checkout - $19.99
                {showPayPal && 
                  
                  <div className="paypal-container">
                    <div id="paypalLoader" className="paypal-loader" style={styleVideoLoader}></div>
                    <div className="paypal-buttons">
                      <PaypalButton/>
                    </div>
                    
                  </div>
                }
              </div>
           
            </div>
            
          </div>

          <div className="HomePageFooter">
              <p className="footerLine1">© <b>Additive Drinking</b></p>
              <p className="footerLine2">Created by Brandon Falcone</p>
              <p className="footerLine3">additivedrinking@gmail.com</p>
          </div>

        </div>
          
        
      </div>
      );
    }
  }
  
  export default withRouter(ContentPurchase);